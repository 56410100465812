import { faker } from "@faker-js/faker";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import Api from "components/api/api";
import { useEffect } from "react";
import { useState } from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { getAdminData } from "redux/slices/adminSlice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const LineChartsDashboard = ({ typeChart }) => {
  let userAdmin = useSelector(getAdminData);

  const [data, setData] = useState(null);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: false,
      title: {
        display: true,
      },
    },
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    console.log("typeChart", typeChart);
    loadData();
  }, [typeChart]);

  const loadData = async () => {
    const reqUrl = "/partner/get-data-chart-doanh-so";
    const params = {
      token_login: userAdmin.token_login,
      email: userAdmin.email,
      type: typeChart,
    };
    const res = await Api.post(reqUrl, params);
    if (res.data.status == true) {
      setData(res.data.data);
    }
  };
  return (
    <>
      {data != null ? (
        <Line
          data={data}
          options={options}
          id="chart-sales-dark"
          className="chart-canvas"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default LineChartsDashboard;
