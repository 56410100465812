import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import HeaderCompany from "components/Headers/HeaderCompany.js";
// react plugin that creates text editor
import { getAdminData } from "redux/slices/adminSlice";
import { useSelector } from "react-redux";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

const HuongDanKetNoiPage = () => {
  let userAdmin = useSelector(getAdminData);

  return (
    <>
      <HeaderCompany name="Hướng dẫn kết nối" parentName="Edited" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Hướng dẫn kết nối hệ thống</h3>
                </CardHeader>
                <CardBody>
                  {/* --------------title--------- */}
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Thông tin kết nối
                        </label>
                        <p>
                          Partner ID:{" "}
                          <b className="txt-red">{userAdmin.partner_id}</b>
                        </p>
                        <p>
                          Partner KEY:{" "}
                          <b className="txt-red">{userAdmin.partner_key}</b>
                        </p>
                        <p>
                          IP register:
                          <b className="txt-red">{userAdmin.ip_register}</b>
                        </p>
                        <p>
                          URL Callback:
                          <b className="txt-red">{userAdmin.url_callback}</b>
                        </p>
                        <p>
                          Key Authorization:{" "}
                          <b className="txt-red">
                            Được cung cấp bằng Email cho Partner
                          </b>
                        </p>
                        <p>
                          Ứng dụng tạo cặp PrivateKey và PublicKey Partner có
                          thể tải ứng dụng này về tạo và sau khi tạo gửi lại cho
                          CoDev file publicKey với extension (.pem):{" "}
                          <a
                            href={`${process.env.REACT_APP_HOT_SERVER}PaygateRSAGenerate.zip`}
                          >
                            Link tải
                          </a>
                        </p>
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <SwaggerUI
                        url={`${process.env.REACT_APP_HOT_SERVER}api_docs.json?v=2`}
                        queryConfigEnabled={true}
                        defaultModelRendering="model"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HuongDanKetNoiPage;
