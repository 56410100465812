import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
// core components
import HeaderCompany from "components/Headers/HeaderCompany.js";
import ToastProvider from "hooks/useToastProvider";
import Api from "components/api/api";
import { useDispatch, useSelector } from "react-redux";
import { getAdminData } from "redux/slices/adminSlice";
import { replaceUser } from "redux/slices/adminSlice";
import ReactQuill from "react-quill";

const CauHinhGuiThongBaoPage = () => {
  const dispatch = useDispatch();

  let userAdmin = useSelector(getAdminData);
  const [isLoading, setIsLoading] = useState(false);
  const [stringAlert, setStringAlert] = useState("");
  const [inputs, setInputs] = useState({
    is_notifi_by_email: false,
    notifi_email_user: "",
    notifi_email_pass: "",
    notifi_email_host: "",
    notifi_email_port: "",
    notifi_email_encryption: "",
    notifi_email_adress: "",
    notifi_email_name: "",
    notifi_email_content: "",
    is_notifi_by_zalo: false,
    notifi_zalo_app_id: "",
    notifi_zalo_secrect_key: "",
    notifi_zalo_zalo_oa_name: "",
  });

  useEffect(() => {
    loadInfoAccount();
  }, []);

  const loadInfoAccount = async () => {
    const reqUrl = "/partner/get-info-account";
    const params = {
      token_login: userAdmin.token_login,
      email: userAdmin.email,
    };
    const res = await Api.post(reqUrl, params);
    if (res.data.status == true) {
      setInputs({
        is_notifi_by_email: res.data.info.is_notifi_by_email,
        notifi_email_user: res.data.info.notifi_email_user,
        notifi_email_pass: res.data.info.notifi_email_pass,
        notifi_email_host: res.data.info.notifi_email_host,
        notifi_email_port: res.data.info.notifi_email_port,
        notifi_email_encryption: res.data.info.notifi_email_encryption,
        notifi_email_adress: res.data.info.notifi_email_adress,
        notifi_email_name: res.data.info.notifi_email_name,
        notifi_email_content: res.data.info.notifi_email_content,
        is_notifi_by_zalo: res.data.info.is_notifi_by_zalo,
        notifi_zalo_app_id: res.data.info.notifi_zalo_app_id,
        notifi_zalo_secrect_key: res.data.info.notifi_zalo_secrect_key,
        notifi_zalo_zalo_oa_name: res.data.info.notifi_zalo_zalo_oa_name,
      });
      console.log("loadInfoAccount", res.data);
      dispatch(replaceUser(res.data.info));
    }
  };

  const handleSubmit = async (event) => {
    console.log("handleSubmitFormThongTin");
    event.preventDefault();
    let isPost = true;

    if (isPost) {
      setIsLoading(true);
      const reqUrl = "/partner/update-info-account";
      const params = { ...inputs };
      params.token_login = userAdmin.token_login;
      params.email = userAdmin.email;
      params.type = "update-notification";

      const res = await Api.post(reqUrl, params);
      if (res.data.status) {
        ToastProvider("success", "Cập nhật thành công");
        setIsLoading(false);
        dispatch(replaceUser(res.data.info));
      } else {
        ToastProvider("error", res.data.message);
        setStringAlert(res.data.message);
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    console.log(name, value);
    if (name == "is_notifi_by_email") {
      value = !inputs.is_notifi_by_email;
    }
    if (name == "is_notifi_by_zalo") {
      value = !inputs.is_notifi_by_zalo;
    }
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleDescriptionChange = (value) => {
    setInputs((values) => ({ ...values, notifi_email_content: value }));
  };

  return (
    <>
      <HeaderCompany
        name="Thông tin tài khoản"
        parentName="Thông tin tài khoản"
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">
                    Thông tin cấu hình gửi thông báo cho khách hàng
                  </h3>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="is_notifi_by_email"
                          >
                            Gửi thông báo bằng Email
                          </label>
                          <div>
                            <label className="custom-toggle">
                              <input
                                checked={
                                  inputs.is_notifi_by_email ? true : false
                                }
                                type="checkbox"
                                name="is_notifi_by_email"
                                id="is_notifi_by_email"
                                value={true}
                                onChange={handleChange}
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Yes"
                              />
                            </label>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="company_tax"
                          >
                            Email Host
                          </label>
                          <Input
                            id="notifi_email_host"
                            name="notifi_email_host"
                            placeholder="Email Host"
                            type="text"
                            value={inputs.notifi_email_host || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="notifi_email_user"
                          >
                            User Email
                          </label>
                          <Input
                            id="notifi_email_user"
                            name="notifi_email_user"
                            placeholder="User Email"
                            type="text"
                            value={inputs.notifi_email_user || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="notifi_email_pass"
                          >
                            Pass Email
                          </label>
                          <Input
                            id="notifi_email_pass"
                            name="notifi_email_pass"
                            placeholder="Pass Email"
                            type="text"
                            value={inputs.notifi_email_pass || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="notifi_email_port"
                          >
                            Port Email
                          </label>
                          <Input
                            id="notifi_email_port"
                            name="notifi_email_port"
                            placeholder="Port Email"
                            type="text"
                            value={inputs.notifi_email_port || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="notifi_email_encryption"
                          >
                            Encryption Email
                          </label>
                          <Input
                            id="notifi_email_encryption"
                            name="notifi_email_encryption"
                            placeholder="Encryption Email"
                            type="text"
                            value={inputs.notifi_email_encryption || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="notifi_email_adress"
                          >
                            Address Email
                          </label>
                          <Input
                            id="notifi_email_adress"
                            name="notifi_email_adress"
                            placeholder="Address Email"
                            type="text"
                            value={inputs.notifi_email_adress || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="notifi_email_name"
                          >
                            Name Email
                          </label>
                          <Input
                            id="notifi_email_name"
                            name="notifi_email_name"
                            placeholder="Name Email"
                            type="text"
                            value={inputs.notifi_email_name || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="company_representative"
                          >
                            Gửi thông báo bằng Zalo OA
                          </label>
                          <div>
                            <label className="custom-toggle">
                              <input
                                checked={
                                  inputs.is_notifi_by_zalo ? true : false
                                }
                                type="checkbox"
                                name="is_notifi_by_zalo"
                                id="is_notifi_by_zalo"
                                value={true}
                                onChange={handleChange}
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Yes"
                              />
                            </label>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="notifi_zalo_app_id"
                          >
                            Zalo App ID
                          </label>
                          <Input
                            id="notifi_zalo_app_id"
                            name="notifi_zalo_app_id"
                            placeholder="Zalo App ID"
                            type="text"
                            value={inputs.notifi_zalo_app_id || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="
                            notifi_zalo_secrect_key"
                          >
                            Secrect Key Zalo App
                          </label>
                          <Input
                            id="
                            notifi_zalo_secrect_key"
                            name="
                            notifi_zalo_secrect_key"
                            placeholder="Secrect Key Zalo App"
                            type="text"
                            value={inputs.notifi_zalo_secrect_key || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="
                            notifi_zalo_access_token"
                          >
                            Access Token Zalo App
                          </label>
                          <Input
                            id="
                            notifi_zalo_access_token"
                            placeholder="Access Token Zalo App"
                            type="text"
                            defaultValue={inputs.notifi_zalo_access_token}
                            readOnly
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="notifi_zalo_zalo_oa_name"
                          >
                            Tên của Zalo OA
                          </label>
                          <Input
                            id="notifi_zalo_zalo_oa_name"
                            name="notifi_zalo_zalo_oa_name"
                            placeholder="Tên của Zalo OA"
                            type="text"
                            value={inputs.notifi_zalo_zalo_oa_name || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="is_notifi_by_email"
                          >
                            Nội dung thông báo qua email
                          </label>
                          <div
                            data-quill-placeholder="Description"
                            data-toggle="quill"
                          />
                          <ReactQuill
                            value={inputs.notifi_email_content || ""}
                            onChange={handleDescriptionChange}
                            theme="snow"
                            name="notifi_email_content"
                            modules={{
                              toolbar: [
                                ["bold", "italic"],
                                ["link", "blockquote", "code", "image"],
                                [
                                  {
                                    list: "ordered",
                                  },
                                  {
                                    list: "bullet",
                                  },
                                ],
                              ],
                            }}
                          />
                        </FormGroup>
                        <div className="mt-3 d-flex d-text align-items-md-end justify-content-md-end">
                          {isLoading ? (
                            <p>{stringAlert ? stringAlert : "Đang xử lý"}</p>
                          ) : (
                            <Button
                              className="btn-icon"
                              color="primary"
                              type="submit"
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="ni ni-bag-17" />
                              </span>
                              <span className="btn-inner--text">Lưu</span>
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default CauHinhGuiThongBaoPage;
