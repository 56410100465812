import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAdminData } from "redux/slices/adminSlice";
import Api from "components/api/api";
import { LINK_API } from "utils/constants";
import ReactQuill from "react-quill";
import ToastProvider from "hooks/useToastProvider";

const FormEdit = ({ isOpen, callBackClose, data }) => {
  let userAdmin = useSelector(getAdminData);
  const [openModal, setOpenModal] = useState(false);
  const [inputs, setInputs] = useState({
    name: "",
    code: "",
    description: "",
    status: 1,
    thumb: null,
  });

  useEffect(() => {
    setOpenModal(isOpen);
    if (data) {
      setInputs({
        name: data.name,
        code: data.code,
        description: data.description,
        status: data.status,
      });
      console.log("edit", data);
    }
  }, [isOpen]);

  const handleDescriptionChange = (value) => {
    setInputs((values) => ({ ...values, description: value }));
  };

  useEffect(() => {
    setOpenModal(isOpen);
  }, [isOpen]);

  useEffect(() => {
    console.log("inputs", inputs);
  }, [inputs]);

  const onClosePopup = () => {
    if (callBackClose) {
      callBackClose.call();
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleFileUpload = (event) => {
    // get the selected file from the input
    const file = event.target.files[0];
    setInputs((values) => ({ ...values, thumb: file }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const reqUrl = LINK_API.GET_UPDATE_PRODUCT;

    const formData = new FormData();
    formData.append("token_login", userAdmin.token_login);
    formData.append("email", userAdmin.email);
    formData.append("id", data.id);
    formData.append("name", inputs.name);
    formData.append("code", inputs.code);
    formData.append("description", inputs.description);
    formData.append("status", inputs.status);
    formData.append("thumb", inputs.thumb);

    const res = await Api.post(reqUrl, formData);

    if (res.data.status == true) {
      console.log("getListHistory", res.data);
      ToastProvider("success", "Cập nhật thành công");
      onClosePopup();
    } else {
      ToastProvider("error", res.data.message);
    }
  };

  return (
    <>
      {data ? (
        <Modal
          isOpen={openModal}
          backdrop="static"
          size="lg"
          style={{ maxWidth: "900px", width: "100%" }}
        >
          <ModalHeader>Edit sản phẩm: {data.name}</ModalHeader>
          <ModalBody>
            <Container className="mt--2" fluid>
              <Row>
                <Col lg="12">
                  <div className="card-wrapper">
                    <Card>
                      <CardBody>
                        <Form onSubmit={handleSubmit}>
                          {/* --------------name--------- */}
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="name"
                            >
                              Name
                            </label>
                            <Input
                              id="name"
                              name="name"
                              placeholder="BÀN ỦI ĐIỆN"
                              type="text"
                              value={inputs.name || ""}
                              onChange={handleChange}
                            />
                          </FormGroup>
                          {/* --------------code--------- */}
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="code"
                            >
                              Code
                            </label>
                            <Input
                              id="code"
                              name="code"
                              placeholder="BUD"
                              type="text"
                              value={inputs.code || ""}
                              onChange={handleChange}
                            />
                          </FormGroup>
                          {/* --------------description--------- */}
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="description"
                            >
                              Description
                            </label>
                            <div
                              data-quill-placeholder="Description"
                              data-toggle="quill"
                            />
                            <ReactQuill
                              value={inputs.description || ""}
                              onChange={handleDescriptionChange}
                              theme="snow"
                              modules={{
                                toolbar: [
                                  ["bold", "italic"],
                                  ["link", "blockquote", "code", "image"],
                                  [
                                    {
                                      list: "ordered",
                                    },
                                    {
                                      list: "bullet",
                                    },
                                  ],
                                ],
                              }}
                            />
                          </FormGroup>
                          {/* --------------thumb--------- */}
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="thumb"
                            >
                              Thumb
                            </label>
                            {data.thumb ? (
                              <div>
                                <img height={150} src={data.thumb} />
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="custom-file">
                              <input
                                className="custom-file-input"
                                id="thumb"
                                lang="en"
                                type="file"
                                name="thumb"
                                onChange={handleFileUpload}
                                accept="image/png, image/jpeg"
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="thumb"
                              >
                                Select file
                              </label>
                            </div>
                          </FormGroup>
                          {/* --------------CheckBox_RadiosBox--------- */}
                          <FormGroup>
                            <Row>
                              <Col lg="12">
                                <label
                                  className="form-control-label"
                                  htmlFor="status"
                                >
                                  Status
                                </label>
                                <div className="custom-control custom-radio custom-control-inline">
                                  <input
                                    className="custom-control-input"
                                    id="status_unactive"
                                    name="status"
                                    type="radio"
                                    value={0}
                                    defaultChecked={
                                      inputs.status == 0 ? true : false
                                    }
                                    onChange={handleChange}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="status_unactive"
                                  >
                                    Unactive
                                  </label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                  <input
                                    className="custom-control-input"
                                    id="status_active"
                                    name="status"
                                    type="radio"
                                    value={1}
                                    defaultChecked={
                                      inputs.status == 1 ? true : false
                                    }
                                    onChange={handleChange}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="status_active"
                                  >
                                    Active
                                  </label>
                                </div>
                              </Col>
                            </Row>
                          </FormGroup>
                          {/* --------------button--------- */}
                          <FormGroup>
                            <Row>
                              <Col lg="12">
                                <Button color="primary" type="submit">
                                  Cập nhật
                                </Button>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Form>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onClosePopup}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default FormEdit;
