import HeaderCompany from "components/Headers/HeaderCompany.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import Select2 from "react-select2-wrapper";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAdminData } from "redux/slices/adminSlice";
import Api from "components/api/api";
import { LINK_API } from "utils/constants";
import ToastProvider from "hooks/useToastProvider";

const WarrantyActivation = () => {
  let userAdmin = useSelector(getAdminData);

  const [dataTable, setDataTable] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [txtSearch, setTxtSearch] = useState("");
  const [productID, setProductID] = useState(0);
  const [modelID, setModelID] = useState(0);
  const [productList, setProductList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [status, setStatus] = useState(0);
  const [fieldSort, setFieldSort] = useState("id");
  const [bySort, setBySort] = useState("asc");

  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [datePopup, setDataPopup] = useState(null);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "code",
      text: "Code",
      sort: true,
    },
    {
      dataField: "serial",
      text: "Serial",
      sort: true,
    },
    {
      dataField: "product",
      text: "Sản phẩm",
      sort: true,
    },
    {
      dataField: "model",
      text: "Model",
      sort: true,
    },
    {
      dataField: "customer_phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Ngày tạo",
      sort: true,
      formatter: (cell, row) => `${new Date(cell).toLocaleString("fr-BE")}`,
    },
    {
      dataField: "warrantyActivationDate",
      text: "Ngày kích hoạt",
      sort: true,
      formatter: (cell, row) =>
        cell ? `${new Date(cell).toLocaleString("fr-BE")}` : "--",
    },
    {
      dataField: "warrantyExpirationDate",
      text: "Ngày hết hạn",
      sort: true,
      formatter: (cell, row) =>
        cell ? `${new Date(cell).toLocaleString("fr-BE")}` : "--",
    },
    {
      dataField: "status",
      text: "Trạng thái",
      sort: true,
      formatter: (cell, row) => (
        <Button
          className="btn-icon d-flex"
          size="sm"
          color="info"
          type="button"
          outline
        >
          <span className="btn-inner--text">
            {row.warrantyActivationDate != ""
              ? "Đã kích hoạt"
              : "Chưa kích hoạt"}
          </span>
        </Button>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <Button
          className="btn-icon ml-2 form-control"
          color="primary"
          type="button"
          onClick={() => {
            onClickShowDetail(row.id);
          }}
        >
          <span className="btn-inner--text">Detail</span>
        </Button>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: fieldSort,
      order: bySort,
    },
  ];

  useEffect(() => {
    console.log("useEffect 1");
    getListProduct();
    getListItemProduct();
  }, []);

  useEffect(() => {
    if (productID > 0) {
      getListModelProduct();
    }
  }, [productID]);

  useEffect(() => {
    setDataTable([]);
    console.log("useEffect 2");
    getListItemProduct();
  }, [pageCurrent, sizePage, fieldSort, bySort]);

  const getListItemProduct = async () => {
    const reqUrl = LINK_API.GET_LIST_WARRANTY_ACTIVATION;
    const params = {
      token_login: userAdmin.token_login,
      email: userAdmin.email,
      page: pageCurrent,
      pageSize: sizePage,
      search: txtSearch,
      status: status,
      product_id: productID,
      model_id: modelID,
      fieldSort: fieldSort,
      bySort: bySort,
    };
    const res = await Api.post(reqUrl, params);
    if (res.data.status == true) {
      console.log("getListItemProduct", res.data.data);
      setDataTable(res.data.data.data);
      setTotalItem(res.data.data.pagination.total);
    }
  };

  const getListProduct = async () => {
    const reqUrl = LINK_API.GET_LIST_PRODUCT;
    const params = {
      token_login: userAdmin.token_login,
      email: userAdmin.email,
      page: pageCurrent,
      pageSize: 1000,
      status: 1,
    };
    const res = await Api.post(reqUrl, params);
    if (res.data.status == true) {
      console.log("getListProduct", res.data.data.data);
      let products = [];
      res.data.data.data.forEach((item) => {
        products.push({
          id: item.id,
          text: "[" + item.code + "] " + item.name,
        });
      });
      setProductList(products);
    }
  };

  const getListModelProduct = async () => {
    const reqUrl = LINK_API.GET_LIST_MODEL;
    const params = {
      token_login: userAdmin.token_login,
      email: userAdmin.email,
      page: pageCurrent,
      pageSize: 1000,
      status: 1,
      product_id: productID,
    };
    const res = await Api.post(reqUrl, params);
    if (res.data.status == true) {
      console.log("models", res.data.data.data);
      let models = [];
      res.data.data.data.forEach((item) => {
        models.push({
          id: item.id,
          text: "[" + item.code + "] " + item.title,
        });
      });
      setModelList(models);
    }
  };

  const onSearchChange = () => {
    setPageCurrent(1);
    getListItemProduct();
  };

  const onTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    const currentIndex = (page - 1) * sizePerPage;
    console.log(
      "onTableChange",
      type,
      page,
      sizePage,
      filters,
      sortField,
      sortOrder
    );
    if (type == "pagination") {
      setPageCurrent(page);
      setSizePage(sizePage);
    } else if (type == "sort") {
      setFieldSort(sortField);
      setBySort(sortOrder);
    }
  };

  const toggleModel = () => {
    setOpenModalDetail(!openModalDetail);
  };

  const onClickShowDetail = (id) => {
    let tmp = null;
    for (const it of dataTable) {
      if (it.id == id) {
        tmp = it;
        break;
      }
    }
    if (tmp) {
      setDataPopup(tmp);
      setOpenModalDetail(true);
    } else {
      ToastProvider("error", "Không có data của lịch sử này");
    }
  };

  return (
    <>
      <HeaderCompany name="Manage Partner" parentName="Manage Partner" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 className="mb-0">
                  Danh sách kích hoạt bảo hành:{" "}
                  {totalItem.toLocaleString("it-IT")} dòng
                </h3>
              </CardHeader>
              <CardBody>
                <div className="py-4 table-responsive">
                  <Row className="mb-5">
                    <Col lg="3">
                      <label className="form-control-label" htmlFor="end_date">
                        Code / Serial / Điện thoại KH
                      </label>
                      <Input
                        defaultValue={txtSearch}
                        id="txt_search"
                        type="text"
                        onChange={(e) => setTxtSearch(e.target.value)}
                      />
                    </Col>
                    <Col lg="3">
                      <label className="form-control-label" htmlFor="end_date">
                        Sản phẩm
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn sản phẩm",
                        }}
                        value={productID}
                        onChange={(e) => setProductID(e.target.value)}
                        defaultValue={status}
                        data={[{ id: 0, text: "Tất cả" }, ...productList]}
                      />
                    </Col>
                    <Col lg="2">
                      <label className="form-control-label" htmlFor="end_date">
                        Model
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn sản phẩm",
                        }}
                        value={modelID}
                        onChange={(e) => setModelID(e.target.value)}
                        defaultValue={status}
                        data={[{ id: 0, text: "Tất cả" }, ...modelList]}
                      />
                    </Col>
                    <Col lg="2">
                      <label className="form-control-label" htmlFor="end_date">
                        Trạng thái
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn trạng thái",
                        }}
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        defaultValue={status}
                        data={[
                          { id: 0, text: "Tất cả" },
                          { id: 1, text: "Đã kích hoạt" },
                          { id: 2, text: "Chưa kích hoạt" },
                        ]}
                      />
                    </Col>
                    <Col lg="2">
                      <label className="form-control-label" htmlFor="btn">
                        &nbsp;
                      </label>
                      <Button
                        className="btn-icon ml-2 form-control"
                        color="primary"
                        type="button"
                        onClick={onSearchChange}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-bag-17" />
                        </span>
                        <span className="btn-inner--text">Search</span>
                      </Button>
                    </Col>
                  </Row>
                  {dataTable != null && dataTable.length > 0 ? (
                    <BootstrapTable
                      remote
                      keyField="id"
                      data={dataTable}
                      columns={columns}
                      defaultSorted={defaultSorted}
                      filter={filterFactory()}
                      pagination={paginationFactory({
                        page: pageCurrent,
                        sizePerPage: sizePage,
                        totalSize: totalItem,
                      })}
                      onTableChange={onTableChange}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal isOpen={openModalDetail} backdrop="static">
        {datePopup ? (
          <>
            <ModalHeader>
              Chi tiết sản phẩm có số serial: {datePopup.serial}
            </ModalHeader>
            <ModalBody>
              <table
                id="tblHistoryTransaction"
                className="table table-noborder table-nowrap popup-table"
              >
                <tbody>
                  <tr>
                    <td>Code:</td>
                    <td>{datePopup.code}</td>
                  </tr>
                  <tr>
                    <td>Serial:</td>
                    <td>{datePopup.serial}</td>
                  </tr>
                  <tr>
                    <td>Sản phẩm:</td>
                    <td>{datePopup.product}</td>
                  </tr>
                  <tr>
                    <td>Model:</td>
                    <td>{datePopup.model}</td>
                  </tr>
                  <tr>
                    <td>Model:</td>
                    <td>{datePopup.model}</td>
                  </tr>
                  <tr>
                    <td>Thời gian tạo:</td>
                    <td>
                      {new Date(datePopup.createdAt).toLocaleString("fr-BE")}
                    </td>
                  </tr>
                  <tr>
                    <td>Trạng thái kích hoạt bảo hành:</td>
                    <td>
                      {datePopup.warrantyActivationDate
                        ? "Đã kích hoạt bảo hành"
                        : "Chưa kích hoạt"}
                    </td>
                  </tr>
                  <tr>
                    <td>Thời gian kích hoạt bảo hành:</td>
                    <td>
                      {datePopup.warrantyActivationDate
                        ? new Date(
                            datePopup.warrantyActivationDate
                          ).toLocaleString("fr-BE")
                        : "--"}
                    </td>
                  </tr>
                  <tr>
                    <td>Thời gian hết bảo hành:</td>
                    <td>
                      {datePopup.warrantyExpirationDate
                        ? new Date(
                            datePopup.warrantyExpirationDate
                          ).toLocaleString("fr-BE")
                        : "--"}
                    </td>
                  </tr>
                  <tr>
                    <td>Số ngày được bảo hành:</td>
                    <td>{datePopup.numberOfWarrantyDays}</td>
                  </tr>
                  <tr>
                    <td>Trạng thái bảo hành:</td>
                    <td>
                      {datePopup.warrantyStatus == "YES"
                        ? "Đang bảo hành"
                        : "Không bảo hành"}
                    </td>
                  </tr>
                  <tr>
                    <td>Tên khách hàng:</td>
                    <td>{datePopup.customer_name}</td>
                  </tr>
                  <tr>
                    <td>Điện thoại khách hàng:</td>
                    <td>{datePopup.customer_phone}</td>
                  </tr>
                  <tr>
                    <td>Email khách hàng:</td>
                    <td>{datePopup.customer_email}</td>
                  </tr>
                  <tr>
                    <td>Địa chỉ khách hàng</td>
                    <td>{datePopup.customer_address}</td>
                  </tr>
                </tbody>
              </table>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModel}>
                Close
              </Button>
            </ModalFooter>
          </>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};

export default WarrantyActivation;
