import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select2 from "react-select2-wrapper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAdminData } from "redux/slices/adminSlice";
import Api from "components/api/api";
import { LINK_API } from "utils/constants";
import ToastProvider from "hooks/useToastProvider";

const FormEdit = ({ isOpen, callBackClose, data, productList }) => {
  let userAdmin = useSelector(getAdminData);
  const [openModal, setOpenModal] = useState(false);
  const [inputs, setInputs] = useState({
    name: "",
    count_product: 0,
    product_id: 0,
    model_id: 0,
    status: "moi-tao",
    numberOfWarrantyDays: 0,
    type_export: "export-both",
  });
  const [modelList, setModelList] = useState([]);

  useEffect(() => {
    setOpenModal(isOpen);
    if (data) {
      setInputs({
        name: data.name,
        count_product: data.count_product,
        product_id: data.product_id,
        model_id: data.model_id,
        status: data.status,
        numberOfWarrantyDays: data.numberOfWarrantyDays,
        type_export: data.type_export,
      });
      console.log("edit model", data);
      if (data.product_id > 0) {
        getListModelProduct(data.product_id);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    setOpenModal(isOpen);
  }, [isOpen]);

  const onClosePopup = () => {
    if (callBackClose) {
      callBackClose.call();
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));

    if (name == "product_id" && value > 0) {
      getListModelProduct(value);
    }
  };

  const getListModelProduct = async (product_id) => {
    const reqUrl = LINK_API.GET_LIST_MODEL;
    const params = {
      token_login: userAdmin.token_login,
      email: userAdmin.email,
      page: 1,
      pageSize: 1000,
      status: 1,
      product_id: product_id,
    };
    const res = await Api.post(reqUrl, params);
    if (res.data.status == true) {
      console.log("models", res.data.data.data);
      let models = [];
      res.data.data.data.forEach((item) => {
        models.push({
          id: item.id,
          text: "[" + item.code + "] " + item.title,
        });
      });
      setModelList(models);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const reqUrl = LINK_API.GET_UPDATE_PRODUCT_WAREHOUSE;

    const formData = new FormData();
    formData.append("token_login", userAdmin.token_login);
    formData.append("email", userAdmin.email);
    formData.append("product_id", inputs.product_id);
    formData.append("model_id", inputs.model_id);
    formData.append("name", inputs.name);
    formData.append("count_product", inputs.count_product);
    formData.append("status", inputs.status);
    formData.append("numberOfWarrantyDays", inputs.numberOfWarrantyDays);
    formData.append("type_export", inputs.type_export);

    const res = await Api.post(reqUrl, formData);

    if (res.data.status == true) {
      console.log("getListHistory", res.data);
      ToastProvider("success", "Cập nhật thành công");
      onClosePopup();
    } else {
      ToastProvider("error", res.data.message);
    }
  };

  return (
    <>
      {data ? (
        <Modal
          isOpen={openModal}
          backdrop="static"
          size="lg"
          style={{ maxWidth: "900px", width: "100%" }}
        >
          <ModalHeader>Chỉnh nhập kho: {data.name}</ModalHeader>
          <ModalBody>
            <Container className="mt--2" fluid>
              <Row>
                <Col lg="12">
                  <div className="card-wrapper">
                    <Card>
                      <CardBody>
                        <Form onSubmit={handleSubmit}>
                          {/* --------------product--------- */}
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="name"
                            >
                              Product
                            </label>
                            <Select2
                              className={`form-control`}
                              options={{
                                placeholder: "Chọn sản phẩm",
                              }}
                              value={inputs.product_id}
                              onChange={handleChange}
                              defaultValue={inputs.product_id}
                              name="product_id"
                              data={[{ id: 0, text: "Tất cả" }, ...productList]}
                            />
                          </FormGroup>
                          {/* --------------model--------- */}
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="name"
                            >
                              Model
                            </label>
                            <Select2
                              className={`form-control`}
                              options={{
                                placeholder: "Chọn model",
                              }}
                              name="model_id"
                              value={inputs.model_id}
                              onChange={handleChange}
                              defaultValue={inputs.model_id}
                              data={[{ id: 0, text: "Tất cả" }, ...modelList]}
                            />
                          </FormGroup>
                          {/* --------------name--------- */}
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="code"
                            >
                              Name
                            </label>
                            <Input
                              id="name"
                              name="name"
                              placeholder="Bình đun nước KT-1818 50 - 2509/2023"
                              type="text"
                              value={inputs.name || ""}
                              onChange={handleChange}
                            />
                          </FormGroup>
                          {/* --------------count_product--------- */}
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="count_product"
                            >
                              Code
                            </label>
                            <Input
                              id="count_product"
                              name="count_product"
                              placeholder="1000"
                              type="number"
                              value={inputs.count_product || 0}
                              onChange={handleChange}
                            />
                          </FormGroup>
                          {/* --------------status--------- */}
                          <FormGroup>
                            <Row>
                              <Col lg="12">
                                <label
                                  className="form-control-label"
                                  htmlFor="status"
                                >
                                  Trạng thái
                                </label>
                                <Select2
                                  className={`form-control`}
                                  options={{
                                    placeholder: "Chọn trạng thái",
                                  }}
                                  name="status"
                                  id="status"
                                  value={inputs.status}
                                  onChange={handleChange}
                                  defaultValue={inputs.status}
                                  data={[
                                    {
                                      id: "moi-tao",
                                      text: "Mới tạo. Hệ thống chưa tạo qrcode",
                                    },
                                    {
                                      id: "confirm-tao-qrcode",
                                      text: "Confirm để hệ thống tạo qrcode",
                                    },
                                  ]}
                                />
                                <p>
                                  Lưu ý: Trạng thái "Mới Tạo" khi bạn chọn trạng
                                  thái này thì hệ thống sẽ lưu lại thông tin
                                  nhưng chưa tạo mã code. Và bạn có thể chỉnh
                                  sửa nếu bạn nhập sau. Trạng thái "Confirm" Là
                                  khi bạn quyết định tạo mã code thì hệ thống sẽ
                                  khoá lại và hệ thống tự động tạo mã. Thời gian
                                  tạo mất khoản 1 phút và sau đó bạn reload lại
                                  trang bạn sẽ thấy có button "Download" để
                                  download mã QRCode.
                                </p>
                              </Col>
                            </Row>
                          </FormGroup>
                          {/* --------------type_export--------- */}
                          <FormGroup>
                            <Row>
                              <Col lg="12">
                                <label
                                  className="form-control-label"
                                  htmlFor="type_export"
                                >
                                  Loại Export
                                </label>
                                <Select2
                                  className={`form-control`}
                                  options={{
                                    placeholder: "Chọn loại",
                                  }}
                                  name="type_export"
                                  id="type_export"
                                  value={inputs.type_export}
                                  onChange={handleChange}
                                  defaultValue={inputs.type_export}
                                  data={[
                                    { id: "export-excel", text: "Xuất excel" },
                                    {
                                      id: "export-folder-qrcode",
                                      text: "Xuất QRCode",
                                    },
                                    { id: "export-both", text: "Xuất cả 2" },
                                  ]}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          {/* --------------numberOfWarrantyDays--------- */}
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="numberOfWarrantyDays"
                            >
                              Số ngày bảo hành
                            </label>
                            <Input
                              id="numberOfWarrantyDays"
                              name="numberOfWarrantyDays"
                              placeholder="1000"
                              type="number"
                              value={inputs.numberOfWarrantyDays || 0}
                              onChange={handleChange}
                            />
                          </FormGroup>
                          {/* --------------button--------- */}
                          <FormGroup>
                            <Row>
                              <Col lg="12">
                                <Button color="primary" type="submit">
                                  Cập nhật
                                </Button>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Form>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onClosePopup}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default FormEdit;
