import { LINK_API } from "utils/constants";
import Api from "./api";

export const loginAdminAPI = async (props) => {
  const { email, password } = props;
  const reqUrl = LINK_API.LOGIN;
  const res = await Api.post(reqUrl, props);
  return res.data;
};

export const logout = async () => {
  const reqUrl = LINK_API.LOGOUT;
  const res = await Api.post(reqUrl);
  return res;
};
