const routes = [
  {
    collapse: true,
    name: "Tài Khoản",
    icon: "ni ni-single-02 text-primary",
    state: "taiKhoan",
    views: [
      {
        path: "/dashboard",
        name: "Bảng tổng hợp",
        miniName: "T",
        layout: "/admin",
      },
      {
        path: "/information",
        name: "Thông Tin Tài Khoản",
        miniName: "T",
        layout: "/admin",
      },
      {
        path: "/logout",
        name: "Logout",
        miniName: "N",
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Sản phẩm",
    icon: "ni ni-bag-17 text-primary",
    state: "products",
    views: [
      {
        path: "/products",
        name: "Loại Sản phẩm",
        miniName: "N",
        layout: "/admin",
      },
      {
        path: "/model",
        name: "Model",
        miniName: "N",
        layout: "/admin",
      },
      {
        path: "/product-warehouse",
        name: "Nhập kho",
        miniName: "N",
        layout: "/admin",
      },
      {
        path: "/warranty-activation",
        name: "DS Kích hoạt bảo hành",
        miniName: "T",
        layout: "/admin",
      },
      {
        path: "/qrcode-generation",
        name: "QRCode Tự tạo",
        miniName: "T",
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Tài chính",
    icon: "ni ni-chart-pie-35 text-primary",
    state: "finance",
    views: [
      {
        path: "/payment",
        name: "Nạp tiền",
        miniName: "N",
        layout: "/admin",
      },
      {
        path: "/history-payment",
        name: "Lịch sử nạp tiền",
        miniName: "N",
        layout: "/admin",
      },
      {
        path: "/history-transaction",
        name: "Lịch sử giao dịch",
        miniName: "N",
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Cấu hình",
    icon: "ni ni-settings text-primary",
    state: "settings",
    views: [
      {
        path: "/cau-hinh-gui-thong-bao",
        name: "Cấu hình gửi thông báo",
        miniName: "D",
        layout: "/admin",
      },
      {
        path: "/huong-dan-ket-noi",
        name: "Hướng dẫn kết nối",
        miniName: "D",
        layout: "/admin",
      },
      {
        path: "/invoice",
        name: "Tra cứu hóa đơn điện tử",
        miniName: "D",
        layout: "/admin",
      },
    ],
  },
];

export default routes;
