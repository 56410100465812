import HeaderCompany from "components/Headers/HeaderCompany.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import Select2 from "react-select2-wrapper";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Input,
} from "reactstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAdminData } from "redux/slices/adminSlice";
import Api from "components/api/api";
import { LINK_API, STATUS_WAREHOUSE, TYPE_EXPORT } from "utils/constants";

import FormAdd from "./add";
import FormEdit from "./edit";

const QRcodeGeneration = () => {
  let userAdmin = useSelector(getAdminData);

  const [dataTable, setDataTable] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [txtSearch, setTxtSearch] = useState("");
  const [status, setStatus] = useState("");

  const [fieldSort, setFieldSort] = useState("id");
  const [bySort, setBySort] = useState("asc");

  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "link_custom",
      text: "Link",
      sort: true,
    },
    {
      dataField: "link_redirect",
      text: "Link đích",
      sort: true,
    },
    {
      dataField: "count_redirect",
      text: "Count Click",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Ngày tạo",
      sort: true,
      formatter: (cell, row) => `${new Date(cell).toLocaleString("fr-BE")}`,
    },
    {
      dataField: "status",
      text: "Trạng thái",
      sort: true,
      formatter: (cell) => (
        <Button
          className="btn-icon d-flex"
          size="sm"
          color="info"
          type="button"
          outline
        >
          <span className="btn-inner--text">
            {cell == STATUS_WAREHOUSE.MOI_TAO
              ? "Mới Tạo"
              : cell == STATUS_WAREHOUSE.CONFIRM_TAO_QRCODE
              ? "Confirm"
              : cell == STATUS_WAREHOUSE.DANG_TAO_QRCODE
              ? "Đang tạo"
              : cell == STATUS_WAREHOUSE.HOAN_THANH_TAO_QRCODEL
              ? "Tạo thành công"
              : "--"}
          </span>
        </Button>
      ),
    },
    {
      dataField: "link_folder_qrcode",
      text: "QRCODE",
      sort: true,
      formatter: (cell) =>
        cell ? (
          <Button
            className="btn-icon d-flex"
            size="sm"
            color="info"
            type="button"
            outline
            onClick={() => downloadFile(cell)}
          >
            <span className="btn-inner--text">Download</span>
          </Button>
        ) : (
          <></>
        ),
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: (cell, row) => {
        return (
          <Button
            className="btn-icon d-flex"
            size="sm"
            color="info"
            type="button"
            outline
            onClick={() => {
              onPopupEdit(row);
            }}
          >
            <span className="btn-inner--text">Edit</span>
          </Button>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: fieldSort,
      order: bySort,
    },
  ];

  useEffect(() => {
    console.log("useEffect 1");
    getListQRCodeGeneration();
  }, []);

  useEffect(() => {
    setDataTable([]);
    console.log("useEffect 2");
    getListQRCodeGeneration();
  }, [pageCurrent, sizePage, fieldSort, bySort]);

  const getListQRCodeGeneration = async () => {
    const reqUrl = LINK_API.GET_LIST_QRCODE_GENERATION;
    const params = {
      token_login: userAdmin.token_login,
      email: userAdmin.email,
      page: pageCurrent,
      pageSize: sizePage,
      search: txtSearch,
      status: status,
      fieldSort: fieldSort,
      bySort: bySort,
    };
    const res = await Api.post(reqUrl, params);
    if (res.data.status == true) {
      console.log("getListProductWareHouse", res.data.data);
      setDataTable(res.data.data.data);
      setTotalItem(res.data.data.pagination.total);
    }
  };

  const onSearchChange = () => {
    setPageCurrent(1);
    getListQRCodeGeneration();
  };

  const onTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    const currentIndex = (page - 1) * sizePerPage;
    console.log(
      "onTableChange",
      type,
      page,
      sizePage,
      filters,
      sortField,
      sortOrder
    );
    if (type == "pagination") {
      setPageCurrent(page);
      setSizePage(sizePage);
    } else if (type == "sort") {
      setFieldSort(sortField);
      setBySort(sortOrder);
    }
  };

  const downloadFile = (path) => {
    window.open(path, "_blank", "noreferrer");
  };

  const onPopupAdd = () => {
    setOpenModalAdd(true);
  };

  const onClosePopupAdd = () => {
    setOpenModalAdd(false);
    setDataTable([]);
    getListQRCodeGeneration();
  };

  useEffect(() => {
    if (dataEdit) {
      setOpenModalEdit(true);
    } else {
      setOpenModalEdit(false);
    }
  }, [dataEdit]);
  const onPopupEdit = (row) => {
    console.log("row", row);
    setDataEdit(row);
  };

  const onClosePopupEdit = () => {
    setDataEdit(null);
    setDataTable([]);
    getListQRCodeGeneration();
  };

  return (
    <>
      <HeaderCompany name="Manage Partner" parentName="Manage Partner" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 className="mb-0">
                  Danh sách qrcode tự tạo: {totalItem} item
                </h3>
                <Button
                  className="buttons-copy buttons-html5"
                  color="default"
                  size="sm"
                  id="copy-tooltip"
                  onClick={onPopupAdd}
                >
                  <span>Tạo QRCode mới</span>
                </Button>
              </CardHeader>
              <CardBody>
                <div className="py-4 table-responsive">
                  <Row className="mb-5">
                    <Col lg="5">
                      <label className="form-control-label" htmlFor="end_date">
                        Tên / Link / Link điều hướng
                      </label>
                      <Input
                        defaultValue={txtSearch}
                        id="txt_search"
                        type="text"
                        onChange={(e) => setTxtSearch(e.target.value)}
                      />
                    </Col>
                    <Col lg="3">
                      <label className="form-control-label" htmlFor="end_date">
                        Trạng thái
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn trạng thái",
                        }}
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        defaultValue={status}
                        data={[
                          { id: "", text: "Tất cả" },
                          { id: "moi-tao", text: "Mới tạo" },
                          { id: "confirm-tao-qrcode", text: "Đã confirm" },
                          { id: "dang-tao-qrcode", text: "Đang tạo mã" },
                          {
                            id: "hoan-thanh-tao-qrcode",
                            text: "Tạo mã thành công",
                          },
                        ]}
                      />
                    </Col>
                    <Col lg="4">
                      <label className="form-control-label" htmlFor="btn">
                        &nbsp;
                      </label>
                      <Button
                        className="btn-icon ml-2 form-control"
                        color="primary"
                        type="button"
                        onClick={onSearchChange}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-bag-17" />
                        </span>
                        <span className="btn-inner--text">Search</span>
                      </Button>
                    </Col>
                  </Row>
                  {dataTable != null && dataTable.length > 0 ? (
                    <BootstrapTable
                      remote
                      keyField="id"
                      data={dataTable}
                      columns={columns}
                      defaultSorted={defaultSorted}
                      filter={filterFactory()}
                      pagination={paginationFactory({
                        page: pageCurrent,
                        sizePerPage: sizePage,
                        totalSize: totalItem,
                      })}
                      onTableChange={onTableChange}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <FormAdd isOpen={openModalAdd} callBackClose={onClosePopupAdd} />
      <FormEdit
        isOpen={openModalEdit}
        data={dataEdit}
        callBackClose={onClosePopupEdit}
      />
    </>
  );
};

export default QRcodeGeneration;
