import HeaderCompany from "components/Headers/HeaderCompany.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import Select2 from "react-select2-wrapper";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Input,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardTitle,
} from "reactstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAdminData } from "redux/slices/adminSlice";
import Api from "components/api/api";
import { handleBalanceVND, getStatusFromKey } from "utils/helper";
import ToastProvider from "hooks/useToastProvider";

const HistoryTransaction = () => {
  let userAdmin = useSelector(getAdminData);

  const [dataTable, setDataTable] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [endDate, setEndDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [type, setType] = useState("tat-ca");
  const [search, setSearch] = useState("");
  const [fieldSort, setFieldSort] = useState("id");
  const [bySort, setBySort] = useState("asc");
  const [datePopup, setDataPopup] = useState(null);
  const [openModalDetail, setOpenModalDetail] = useState(false);

  const [isExporting, setIsExporting] = useState(false);

  const [summary, setSummary] = useState({
    tong_product_active: 0,
    tong_tien_tra: 0,
    tong_tien_nap: 0,
  });

  const columns = [
    {
      dataField: "time_created",
      text: "Thời gian khởi tạo",
      sort: true,
      formatter: (cell, row) => `${new Date(cell).toLocaleString("fr-BE")}`,
    },
    {
      dataField: "money",
      text: "Tiền",
      sort: true,
      formatter: (cell, row) => handleBalanceVND(cell),
    },
    {
      dataField: "balance_before",
      text: "Tài khoản trước",
      sort: true,
      formatter: (cell, row) => handleBalanceVND(cell),
    },
    {
      dataField: "balance_after",
      text: "Tài khoản sau",
      sort: true,
      formatter: (cell, row) => handleBalanceVND(cell),
    },
    {
      dataField: "type",
      text: "Loại",
      sort: true,
    },
    {
      dataField: "content",
      text: "Nội dung",
      sort: true,
    },
    {
      dataField: "log",
      text: "Log",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: fieldSort,
      order: bySort,
    },
  ];

  useEffect(() => {
    console.log("useEffect 1");
    getListHistory();
  }, []);

  useEffect(() => {
    setDataTable([]);
    //console.log("useEffect 2");
    getListHistory();
  }, [pageCurrent, sizePage, fieldSort, bySort]);

  const getListHistory = async () => {
    const reqUrl = "/partner/get-history-transaction";
    const params = {
      token_login: userAdmin.token_login,
      email: userAdmin.email,
      page: pageCurrent,
      pageSize: sizePage,
      start_date: startDate,
      end_date: endDate,
      type: type,
      search: search,
      fieldSort: fieldSort,
      bySort: bySort,
    };
    const res = await Api.post(reqUrl, params);
    if (res.data.status == true) {
      console.log("getListHistory", res.data.data);
      if (res.data.data.data.length > 0) {
        setDataTable(res.data.data.data);
        setTotalItem(res.data.data.pagination.total);
      }
      if (pageCurrent == 1) {
        setSummary(res.data.summary);
      }
    }
  };

  const onSearchChange = () => {
    console.log("onSearchChange");
    setPageCurrent(1);
    setDataTable([]);
    getListHistory();
  };

  const onTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    const currentIndex = (page - 1) * sizePerPage;
    console.log(
      "onTableChange",
      type,
      page,
      sizePage,
      filters,
      sortField,
      sortOrder
    );
    if (type == "pagination") {
      setPageCurrent(page);
      setSizePage(sizePage);
    } else if (type == "sort") {
      setFieldSort(sortField);
      setBySort(sortOrder);
    }
  };

  const toggleModel = () => {
    setOpenModalDetail(!openModalDetail);
  };

  const onExportExcel = async () => {
    setIsExporting(true);

    const reqUrl = "/partner/export-history-transaction";
    const params = {
      token_login: userAdmin.token_login,
      email: userAdmin.email,
      page: pageCurrent,
      pageSize: sizePage,
      start_date: startDate,
      end_date: endDate,
      type: type,
      search: search,
      fieldSort: fieldSort,
      bySort: bySort,
    };
    try {
      const res = await Api.post(reqUrl, params);
      //console.log("onExportExcel", res.data.data);
      if (res.data.status == true) {
        setIsExporting(false);
        const link = document.createElement("a");
        link.href = res.data.data.url;
        link.setAttribute("download", res.data.data.name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        setIsExporting(false);
        ToastProvider("error", res.data.message);
      }
    } catch (error) {
      setIsExporting(false);
      ToastProvider(
        "error",
        "Có lỗi xảy ra khi export. Bạn có thể export với thời gian ngắn hơn"
      );
    }
  };

  const rowStyleFormat = (row, rowIndex) => {
    if (row.type == "NAP") {
      return "td-nap-tien";
    }
    return "";
  };

  return (
    <>
      <HeaderCompany name="Manage Partner" parentName="Manage Partner" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 className="mb-0">Lịch sử giao dịch {totalItem} item</h3>
              </CardHeader>
              <CardBody>
                <div className="py-4 table-responsive">
                  <Row className="mb-5">
                    <Col md="3" xl="3">
                      <Card className="card-stats card_custom">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Tổng sản phẩm kích hoạt
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {summary.tong_product_active.toLocaleString()}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md="3" xl="3">
                      <Card className="card-stats card_custom">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Tổng tiền trả kích hoạt
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {summary.tong_tien_tra.toLocaleString()}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md="3" xl="3">
                      <Card className="card-stats card_custom">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Tổng tiền nạp
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {summary.tong_tien_nap.toLocaleString()}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md="3" xl="3">
                      <Card className="card-stats card_custom">
                        <CardBody>
                          <Row>
                            <div className="col">
                              {isExporting ? (
                                <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  Hệ thống đang export
                                </CardTitle>
                              ) : (
                                <Button
                                  className="btn-icon ml-2 form-control"
                                  color="primary"
                                  type="button"
                                  onClick={onExportExcel}
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i className="ni ni-bag-17" />
                                  </span>
                                  <span className="btn-inner--text">
                                    Export Excel
                                  </span>
                                </Button>
                              )}
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-5">
                    <Col lg="2">
                      <label
                        className="form-control-label"
                        htmlFor="start_date"
                      >
                        Từ ngày
                      </label>
                      <Input
                        defaultValue={startDate}
                        id="start_date"
                        type="date"
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Col>
                    <Col lg="2">
                      <label className="form-control-label" htmlFor="end_date">
                        Đến ngày
                      </label>
                      <Input
                        defaultValue={endDate}
                        id="end_date"
                        type="date"
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </Col>
                    <Col lg="3">
                      <label className="form-control-label" htmlFor="end_date">
                        Loại
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn loại",
                        }}
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        defaultValue={type}
                        data={[
                          { id: "tat-ca", text: "Tất cả" },
                          { id: "NAP", text: "Nạp" },
                          { id: "RUT", text: "Thanh toán" },
                        ]}
                      />
                    </Col>
                    <Col lg="3">
                      <label className="form-control-label" htmlFor="end_date">
                        Nội dung tìm kiếm
                      </label>
                      <Input
                        defaultValue={search}
                        id="search"
                        type="text"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Col>
                    <Col lg="2">
                      <label className="form-control-label" htmlFor="btn">
                        &nbsp;
                      </label>
                      <Button
                        className="btn-icon ml-2 form-control"
                        color="primary"
                        type="button"
                        onClick={onSearchChange}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-bag-17" />
                        </span>
                        <span className="btn-inner--text">Search</span>
                      </Button>
                    </Col>
                  </Row>
                  {dataTable != null && dataTable.length > 0 ? (
                    <BootstrapTable
                      remote
                      keyField="id"
                      data={dataTable}
                      columns={columns}
                      rowClasses={rowStyleFormat}
                      defaultSorted={defaultSorted}
                      filter={filterFactory()}
                      pagination={paginationFactory({
                        page: pageCurrent,
                        sizePerPage: sizePage,
                        totalSize: totalItem,
                      })}
                      onTableChange={onTableChange}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal isOpen={openModalDetail} backdrop="static">
        {datePopup ? (
          <>
            <ModalHeader>
              Chi tiết giao dịch - {datePopup.request_partner_id}
            </ModalHeader>
            <ModalBody>
              <table
                id="tblHistoryTransaction"
                className="table table-noborder table-nowrap popup-table"
              >
                <tbody>
                  <tr>
                    <td>Thời gian bắt đầu:</td>
                    <td>
                      {new Date(datePopup.time_created).toLocaleString("fr-BE")}
                    </td>
                  </tr>
                  <tr>
                    <td>Thời gian kết thúc:</td>
                    <td>
                      {new Date(datePopup.time_done).toLocaleString("fr-BE")}
                    </td>
                  </tr>
                  <tr>
                    <td>Mã giao dịch:</td>
                    <td>{datePopup.transaction_id}</td>
                  </tr>
                  <tr>
                    <td>Mã giao dịch của đối tác:</td>
                    <td>{datePopup.request_partner_id}</td>
                  </tr>
                  <tr>
                    <td>Sản phẩm:</td>
                    <td>{datePopup.service + " " + datePopup.telco}</td>
                  </tr>
                  <tr>
                    <td>Mệnh giá:</td>
                    <td>{datePopup.menh_gia} đ</td>
                  </tr>
                  <tr>
                    <td>Số lượng:</td>
                    <td>{datePopup.amount}</td>
                  </tr>
                  <tr>
                    <td>Chiết khấu:</td>
                    <td>{datePopup.chiet_khau_partner}%</td>
                  </tr>
                  <tr>
                    <td>Giảm giá:</td>
                    <td>
                      {handleBalanceVND(
                        datePopup.menh_gia_money *
                          datePopup.amount *
                          (datePopup.chiet_khau_partner / 100)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Phí giao dịch:</td>
                    <td> Miễn Phí </td>
                  </tr>
                  <tr>
                    <td>Thành tiền:</td>
                    <td>{handleBalanceVND(datePopup.money_sell)}</td>
                  </tr>
                  <tr>
                    <td>Phí tiện ích thanh toán:</td>
                    <td> Miễn Phí </td>
                  </tr>
                  <tr>
                    <td>Tổng thanh toán:</td>
                    <td>
                      <span className="txt-red">
                        {handleBalanceVND(datePopup.money_sell)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Số tiền trước khi mua:</td>
                    <td>
                      <span className="txt-green">
                        {handleBalanceVND(datePopup.partner_balance_truoc)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Số tiền sau khi mua:</td>
                    <td>
                      <span className="txt-green">
                        {handleBalanceVND(datePopup.partner_balance_sau)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Trạng thái:</td>
                    <td>
                      <span
                        className={getStatusFromKey(datePopup.status).class}
                      >
                        {getStatusFromKey(datePopup.status).name}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModel}>
                Close
              </Button>
            </ModalFooter>
          </>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};

export default HistoryTransaction;
