import ToastProvider from "hooks/useToastProvider";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAdminData } from "redux/slices/adminSlice";
import Api from "components/api/api";
import { LINK_API } from "utils/constants";
import Select2 from "react-select2-wrapper";

const FormAdd = ({ isOpen, callBackClose }) => {
  let userAdmin = useSelector(getAdminData);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    link_custom: "",
    link_redirect: "",
    status: "moi-tao",
  });

  useEffect(() => {
    setOpenModalAdd(isOpen);
  }, [isOpen]);

  useEffect(() => {
    console.log("inputs", inputs);
  }, [inputs]);

  const onClosePopupAdd = () => {
    if (callBackClose) {
      callBackClose.call();
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const reqUrl = LINK_API.GET_INSERT_QRCODE_GENERATION;

    const formData = new FormData();
    formData.append("token_login", userAdmin.token_login);
    formData.append("email", userAdmin.email);
    formData.append("title", inputs.title);
    formData.append("link_custom", inputs.link_custom);
    formData.append("link_redirect", inputs.link_redirect);
    formData.append("status", inputs.status);

    const res = await Api.post(reqUrl, formData);

    if (res.data.status == true) {
      console.log("getListHistory", res.data);
      ToastProvider("success", "Thêm thành công");
      onClosePopupAdd();
    } else {
      ToastProvider("error", res.data.message);
    }
  };

  return (
    <>
      <Modal
        isOpen={openModalAdd}
        backdrop="static"
        size="lg"
        style={{ maxWidth: "900px", width: "100%" }}
      >
        <ModalHeader>Tạo QRCode mới</ModalHeader>
        <ModalBody>
          <Container className="mt--2" fluid>
            <Row>
              <Col lg="12">
                <div className="card-wrapper">
                  <Card>
                    <CardBody>
                      <Form onSubmit={handleSubmit}>
                        {/* --------------title--------- */}
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            Name
                          </label>
                          <Input
                            id="title"
                            name="title"
                            placeholder="Bếp hồng ngoại đơn - DQT-100"
                            type="text"
                            value={inputs.title || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        {/* --------------link_custom--------- */}
                        <FormGroup>
                          <label className="form-control-label" htmlFor="code">
                            Link mong muốn
                          </label>
                          <Input
                            id="link_custom"
                            name="link_custom"
                            placeholder={
                              userAdmin.link_website +
                              "/product/bep-hong-ngoai-don-dqt-100"
                            }
                            type="text"
                            value={inputs.link_custom || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        {/* --------------link_redirect--------- */}
                        <FormGroup>
                          <label className="form-control-label" htmlFor="code">
                            Link chuyển hướng
                          </label>
                          <Input
                            id="link_redirect"
                            name="link_redirect"
                            placeholder="https://osaka.com.vn"
                            type="text"
                            value={inputs.link_redirect || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>

                        {/* --------------status--------- */}
                        <FormGroup>
                          <Row>
                            <Col lg="12">
                              <label
                                className="form-control-label"
                                htmlFor="status"
                              >
                                Trạng thái
                              </label>
                              <Select2
                                className={`form-control`}
                                options={{
                                  placeholder: "Chọn trạng thái",
                                }}
                                name="status"
                                id="status"
                                value={inputs.status}
                                onChange={handleChange}
                                defaultValue={inputs.status}
                                data={[
                                  {
                                    id: "moi-tao",
                                    text: "Mới tạo. Hệ thống chưa tạo qrcode",
                                  },
                                  {
                                    id: "confirm-tao-qrcode",
                                    text: "Confirm để hệ thống tạo qrcode",
                                  },
                                ]}
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                        {/* --------------button--------- */}
                        <FormGroup>
                          <Row>
                            <Col lg="12">
                              <Button color="primary" type="submit">
                                Thêm
                              </Button>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClosePopupAdd}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default FormAdd;
