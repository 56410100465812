import ToastProvider from "hooks/useToastProvider";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select2 from "react-select2-wrapper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAdminData } from "redux/slices/adminSlice";
import Api from "components/api/api";
import { LINK_API } from "utils/constants";

const FormAdd = ({ isOpen, callBackClose, productList }) => {
  let userAdmin = useSelector(getAdminData);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    code: "",
    product_id: 0,
    status: 1,
  });

  useEffect(() => {
    setOpenModalAdd(isOpen);
  }, [isOpen]);

  useEffect(() => {
    console.log("inputs", inputs);
  }, [inputs]);

  const onClosePopupAdd = () => {
    if (callBackClose) {
      callBackClose.call();
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const reqUrl = LINK_API.GET_INSERT_MODEL;

    const formData = new FormData();
    formData.append("token_login", userAdmin.token_login);
    formData.append("email", userAdmin.email);
    formData.append("product_id", inputs.product_id);
    formData.append("title", inputs.title);
    formData.append("code", inputs.code);
    formData.append("status", inputs.status);

    const res = await Api.post(reqUrl, formData);

    if (res.data.status == true) {
      console.log("getListHistory", res.data);
      ToastProvider("success", "Thêm thành công");
      onClosePopupAdd();
    } else {
      ToastProvider("error", res.data.message);
    }
  };

  return (
    <>
      <Modal
        isOpen={openModalAdd}
        backdrop="static"
        size="lg"
        style={{ maxWidth: "900px", width: "100%" }}
      >
        <ModalHeader>Thêm Model</ModalHeader>
        <ModalBody>
          <Container className="mt--2" fluid>
            <Row>
              <Col lg="12">
                <div className="card-wrapper">
                  <Card>
                    <CardBody>
                      <Form onSubmit={handleSubmit}>
                        {/* --------------name--------- */}
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            Product
                          </label>
                          <Select2
                            className={`form-control`}
                            options={{
                              placeholder: "Chọn sản phẩm",
                            }}
                            value={inputs.product_id}
                            onChange={handleChange}
                            defaultValue={inputs.product_id}
                            name="product_id"
                            data={[{ id: 0, text: "Tất cả" }, ...productList]}
                          />
                        </FormGroup>
                        {/* --------------title--------- */}
                        <FormGroup>
                          <label className="form-control-label" htmlFor="code">
                            Name
                          </label>
                          <Input
                            id="title"
                            name="title"
                            placeholder="BÀN ỦI ĐIỆN - HA108"
                            type="text"
                            value={inputs.title || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        {/* --------------code--------- */}
                        <FormGroup>
                          <label className="form-control-label" htmlFor="code">
                            Code
                          </label>
                          <Input
                            id="code"
                            name="code"
                            placeholder="HA108"
                            type="text"
                            value={inputs.code || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        {/* --------------CheckBox_RadiosBox--------- */}
                        <FormGroup>
                          <Row>
                            <Col lg="12">
                              <label
                                className="form-control-label"
                                htmlFor="status"
                              >
                                Status
                              </label>
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  className="custom-control-input"
                                  id="status_unactive"
                                  name="status"
                                  type="radio"
                                  value={0}
                                  onChange={handleChange}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="status_unactive"
                                >
                                  Unactive
                                </label>
                              </div>
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  className="custom-control-input"
                                  defaultChecked
                                  id="status_active"
                                  name="status"
                                  type="radio"
                                  value={1}
                                  onChange={handleChange}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="status_active"
                                >
                                  Active
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </FormGroup>
                        {/* --------------button--------- */}
                        <FormGroup>
                          <Row>
                            <Col lg="12">
                              <Button color="primary" type="submit">
                                Thêm
                              </Button>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClosePopupAdd}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default FormAdd;
