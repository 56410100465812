import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAdminData } from "redux/slices/adminSlice";
import Api from "components/api/api";
import { LINK_API, STATUS_WAREHOUSE } from "utils/constants";
import ToastProvider from "hooks/useToastProvider";
import Select2 from "react-select2-wrapper";

const FormEdit = ({ isOpen, callBackClose, data }) => {
  let userAdmin = useSelector(getAdminData);
  const [openModal, setOpenModal] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    link_custom: "",
    link_redirect: "",
    status: "moi-tao",
  });

  useEffect(() => {
    setOpenModal(isOpen);
    if (data) {
      setInputs({
        title: data.title,
        link_custom: data.link_custom,
        link_redirect: data.link_redirect,
        status: data.status,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    setOpenModal(isOpen);
  }, [isOpen]);

  useEffect(() => {
    console.log("inputs", inputs);
  }, [inputs]);

  const onClosePopup = () => {
    if (callBackClose) {
      callBackClose.call();
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleFileUpload = (event) => {
    // get the selected file from the input
    const file = event.target.files[0];
    setInputs((values) => ({ ...values, thumb: file }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const reqUrl = LINK_API.GET_UPDATE_QRCODE_GENERATION;

    const formData = new FormData();
    formData.append("token_login", userAdmin.token_login);
    formData.append("email", userAdmin.email);
    formData.append("id", data.id);
    formData.append("title", inputs.title);
    formData.append("link_custom", inputs.link_custom);
    formData.append("link_redirect", inputs.link_redirect);
    formData.append("status", inputs.status);

    const res = await Api.post(reqUrl, formData);

    if (res.data.status == true) {
      console.log("getListHistory", res.data);
      ToastProvider("success", "Cập nhật thành công");
      onClosePopup();
    } else {
      ToastProvider("error", res.data.message);
    }
  };

  return (
    <>
      {data ? (
        <Modal
          isOpen={openModal}
          backdrop="static"
          size="lg"
          style={{ maxWidth: "900px", width: "100%" }}
        >
          <ModalHeader>Edit QRCode: {data.title}</ModalHeader>
          <ModalBody>
            <Container className="mt--2" fluid>
              <Row>
                <Col lg="12">
                  <div className="card-wrapper">
                    <Card>
                      <CardBody>
                        <Form onSubmit={handleSubmit}>
                          {/* --------------title--------- */}
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="name"
                            >
                              Name
                            </label>
                            <Input
                              id="title"
                              name="title"
                              placeholder="Bếp hồng ngoại đơn - DQT-100"
                              type="text"
                              disabled={
                                data.status == STATUS_WAREHOUSE.MOI_TAO
                                  ? false
                                  : true
                              }
                              value={inputs.title || ""}
                              onChange={handleChange}
                            />
                          </FormGroup>
                          {/* --------------link_custom--------- */}
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="code"
                            >
                              Link mong muốn
                            </label>
                            <Input
                              id="link_custom"
                              name="link_custom"
                              placeholder={
                                userAdmin.link_website +
                                "/product/bep-hong-ngoai-don-dqt-100"
                              }
                              disabled={
                                data.status == STATUS_WAREHOUSE.MOI_TAO
                                  ? false
                                  : true
                              }
                              type="text"
                              value={inputs.link_custom || ""}
                              onChange={handleChange}
                            />
                          </FormGroup>
                          {/* --------------link_redirect--------- */}
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="code"
                            >
                              Link chuyển hướng
                            </label>
                            <Input
                              id="link_redirect"
                              name="link_redirect"
                              placeholder="https://osaka.com.vn"
                              type="text"
                              value={inputs.link_redirect || ""}
                              onChange={handleChange}
                            />
                          </FormGroup>

                          {/* --------------status--------- */}
                          {data.status == STATUS_WAREHOUSE.MOI_TAO ? (
                            <FormGroup>
                              <Row>
                                <Col lg="12">
                                  <label
                                    className="form-control-label"
                                    htmlFor="status"
                                  >
                                    Trạng thái
                                  </label>
                                  <Select2
                                    className={`form-control`}
                                    options={{
                                      placeholder: "Chọn trạng thái",
                                    }}
                                    name="status"
                                    id="status"
                                    value={inputs.status}
                                    onChange={handleChange}
                                    defaultValue={inputs.status}
                                    data={[
                                      {
                                        id: "moi-tao",
                                        text: "Mới tạo. Hệ thống chưa tạo qrcode",
                                      },
                                      {
                                        id: "confirm-tao-qrcode",
                                        text: "Confirm để hệ thống tạo qrcode",
                                      },
                                    ]}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          ) : (
                            <></>
                          )}
                          {/* --------------button--------- */}
                          <FormGroup>
                            <Row>
                              <Col lg="12">
                                <Button color="primary" type="submit">
                                  Cập nhật
                                </Button>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Form>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onClosePopup}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default FormEdit;
